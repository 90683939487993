export function findIndexFromArray(textToFind, array) {
  // debugger //eslint-disable-line
  let slicingIndex = textToFind.indexOf("-") + 2;
  let stringTofind = textToFind.slice(slicingIndex);
  return array.indexOf(stringTofind);
}

export function findIndexFromMetaDataArray(textToFind, array) {
  return array.indexOf(textToFind);
}

export function getSpecificColumnFromPayloadArray(index, array) {
  var SpecificColumn = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i][index] != null && array[i][index] !== "") {
      SpecificColumn.push(array[i][index]);
    }
  }
  return SpecificColumn;
}

export function sortMetaDataArray(metadaData) {
  let sortedArray = [];
  let slicedMetaDataArray = [];
  let slicedMetaDataArrayNotToSort = [];
  for (let i = 0; i < metadaData.length; i++) {
    if (metadaData[i]) {
      let sliceingIndex = metadaData[i].indexOf("-") - 1;
      let slicedString = metadaData[i].slice(0, sliceingIndex);
      slicedMetaDataArray.push(slicedString);
      slicedMetaDataArrayNotToSort.push(slicedString);
    }
  }

  let sortSlicedMetaDataArray = slicedMetaDataArray.sort(function(a, b) {
    return a.length - b.length || a.localeCompare(b);
  });

  for (let q = 0; q < sortSlicedMetaDataArray.length; q++) {
    let indexFromMataData = slicedMetaDataArrayNotToSort.indexOf(
      sortSlicedMetaDataArray[q]
    );
    sortedArray.push(metadaData[indexFromMataData]);
  }

  return sortedArray;
}

// create meta data array according to excel pattern
export function createWorkingMetaDataArray(dataArray) {
  let metaDataArray = [];

  for (let m = 0; m < dataArray.length; m++) {
    let columnNameInLetters = convertColumnNumberToName(m + 1);
    let columnNameToAddToMetaDataArrayToWork = columnNameInLetters.concat(
      " " + "- " + dataArray[m]
    );
    metaDataArray.push(columnNameToAddToMetaDataArrayToWork);
  }

  return metaDataArray;
}

// convert number to letter according to excel pattern
export function convertColumnNumberToName(num) {
  for (var result = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    result = String.fromCharCode(parseInt((num % b) / a) + 65) + result;
  }
  return result;
}

// get only text removing Excel Support Text
export function removeExcelSupportPrefix(textWithPrefix) {
  let slicingIndex = textWithPrefix.indexOf("-") + 2;
  let textWithOutPrefix = textWithPrefix.slice(slicingIndex);
  return textWithOutPrefix;
}
