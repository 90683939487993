<script>
import { Carousel, Slide } from "vue-carousel";

/**
 * upload Instructions
 */
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      displayIDInfo: true
    };
  },
  mounted() {},
  created() {},
  methods: {}
};
</script>

<template>
  <!-- instrcutions -->
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="hori-timeline mt-2">
            <carousel
              class="events navs-carousel"
              id="timeline-carousel"
              :perPageCustom="[
                [240, 1],
                [768, 2],
                [1024, 4]
              ]"
            >
              <slide>
                <div class="item event-list active">
                  <div>
                    <div class="event-date">
                      <h5 class="mb-4">
                        {{ $t("uploadProcessStep2.infoCard.point1.title") }}
                      </h5>
                    </div>
                    <div class="event-down-number">
                      <i
                        class="mdi mdi-numeric-1-circle h1 text-warning down-number-icon"
                      ></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">
                        {{
                          $t("uploadProcessStep2.infoCard.point1.description")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="item event-list active">
                  <div>
                    <div class="event-date">
                      <h5 class="mb-4">
                        {{ $t("uploadProcessStep2.infoCard.point2.title") }}
                      </h5>
                    </div>
                    <div class="event-down-number">
                      <i
                        class="mdi mdi-numeric-2-circle h1 text-warning down-number-icon"
                      ></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">
                        {{
                          $t("uploadProcessStep2.infoCard.point2.description")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="item event-list active">
                  <div>
                    <div class="event-date">
                      <h5 class="mb-4">
                        {{ $t("uploadProcessStep2.infoCard.point3.title") }}
                      </h5>
                    </div>
                    <div class="event-down-number">
                      <i
                        class="mdi mdi-numeric-3-circle h1 text-warning down-number-icon"
                      ></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">
                        {{
                          $t("uploadProcessStep2.infoCard.point3.description")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="item event-list active">
                  <div>
                    <div class="event-date">
                      <h5 class="mb-4">
                        {{ $t("uploadProcessStep2.infoCard.point4.title") }}
                      </h5>
                    </div>
                    <div class="event-down-number">
                      <i
                        class="mdi mdi-numeric-4-circle h1 text-warning down-number-icon"
                      ></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">
                        {{
                          $t("uploadProcessStep2.infoCard.point4.description")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>

            <div class="col-lg-12">
              <b-alert
                v-model="displayIDInfo"
                class="mt-5 text-center"
                variant="info"
                >{{
                  $t("uploadProcessStep2.infoCard.IdColumnRequired")
                }}</b-alert
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
</template>

<style scoped></style>
