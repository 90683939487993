import { ApiCall } from "../apiBase";

class CerificateLevelService {
  /********* get All Quality Score info ********/
  async getAllCertificateLevels() {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCertificateLevels {
            getCertificateLevels {
              name
              qualityScores {
                  IS
                  PS
                  SDS
                  TS
                  IBS
                  CS
                  OES
              }
            }
          }
        `
      }
    });

    if (result?.data?.data?.getCertificateLevels && !result?.data?.errors) {
      return result?.data?.data?.getCertificateLevels;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new CerificateLevelService();
